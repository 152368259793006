import React from "react";
import style from "./banner.module.css";
export default function Banner() {
  return (
    <>
      <div className={style.banner}></div>
      
    </>
  );
}
